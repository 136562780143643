// Styles
import './layout.scss';
import './login-form.scss';
import './header.scss';
import './signup.scss';

// Scripts
import LoginFormHandler from './login-form-handler';

// Initializations
new LoginFormHandler();
