export default class LoginFormHandler {
    private readonly form: HTMLFormElement;
    private readonly loginButton: HTMLButtonElement;
    private readonly loginWithEmailButton: HTMLButtonElement;
    private readonly usernameInput: HTMLInputElement;
    private readonly passwordInput: HTMLInputElement;

    public constructor() {
        const successMessage = document.querySelector('.form__response');
        if (successMessage) {
            // We got to this page just to tell the user that the login was successful. Do nothing.
            return;
        }

        this.form = document.querySelector('.form__form') as HTMLFormElement;
        this.loginButton = document.querySelector('.form__button--login') as HTMLButtonElement;
        this.loginWithEmailButton = document.querySelector('.form__button--email-login') as HTMLButtonElement;
        this.usernameInput = document.querySelector(".form__input--email") as HTMLInputElement;
        this.passwordInput = document.querySelector(".form__input--password") as HTMLInputElement;
        const forgottenPasswordLink = document.querySelector('.form__forgot-password') as HTMLLinkElement;

        this.loginWithEmailButton.onclick = () => this.submitFormEnablingLoginWithoutPassword();


        this.usernameInput.oninput = () => this.toggleButtonsState();
        this.usernameInput.onkeydown = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                this.enterPressedOnUsernameInput();
            }
        };
        this.addFocusBlurBehavior(this.usernameInput);

        this.passwordInput.oninput = () => this.toggleButtonsState();
        this.passwordInput.onkeydown = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                this.enterPressedOnPasswordInput();
            }
        };
        this.addFocusBlurBehavior(this.passwordInput);

        // If the user gets here using the BACK button, the form is re-filled *after* this script is initialized.
        // Setting a small timeout allows the data to be filled in before the state is evaluated.
        setTimeout(() => this.toggleButtonsState(), 100);



        forgottenPasswordLink.onclick = (e) => {
            if (LoginFormHandler.isValidEmail(this.usernameInput.value)) {
                e.preventDefault();
                const forgottenPasswordPage = forgottenPasswordLink.href;
                const escapedEmail = encodeURIComponent(this.usernameInput.value);
                window.location.href = `${forgottenPasswordPage}?email=${escapedEmail}`;
            }
        };
    }

    private toggleButtonsState(): void {
        const validEmail = LoginFormHandler.isValidEmail(this.usernameInput.value);
        const validPassword = this.passwordInput.value.length > 4;

        this.loginWithEmailButton.classList.toggle('form__button--disabled', !validEmail);
        this.loginButton.classList.toggle('form__button--disabled', !validEmail || !validPassword);
    }

    private enterPressedOnUsernameInput(): void {
        // if the login button is enabled we click it
        // as it means that the user already has added a password
        if (this.isEnabled(this.loginButton)) {
            this.loginButton.click();
        }

        // otherwise it means that the password is not there,
        // we press the login with email button if it is enabled
        if (this.isEnabled(this.loginWithEmailButton)) {
            this.loginWithEmailButton.click();
        }
    }

    private enterPressedOnPasswordInput(): void {
        // if the login button is enabled we click it
        if (this.isEnabled(this.loginButton)) {
            this.loginButton.click();
        }
    }

    private addFocusBlurBehavior(element: HTMLInputElement): void {
        element.onfocus = () => element.parentElement.classList.toggle('is-focus', true);
        element.onblur = () => {
            if (element.value === '') element.parentElement.classList.toggle('is-focus', false);
        };
    }

    private static isValidEmail(email: string): boolean {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    private isEnabled(button: HTMLElement): boolean {
        return !button.classList.contains('form__button--disabled');
    }

    private submitFormEnablingLoginWithoutPassword(): void {
        // removing the mandatory field allows us to submit the form
        this.form.removeChild(this.passwordInput.parentNode.parentNode);

        // then we add a hidden input, so the backend will be able to correctly process the request
        const loginWithoutPasswordField = document.createElement('input');
        loginWithoutPasswordField.name = 'login_without_password';
        loginWithoutPasswordField.style.display = 'none';
        loginWithoutPasswordField.value = '1';
        this.form.append(loginWithoutPasswordField);

        this.form.submit();
    }
}
